import mock from './mock'

import './apps/chat'
import './apps/todo'
import './apps/email'
import './apps/invoice'
import './apps/calendar'
import './apps/userList'
import './apps/clientList'
import './apps/serviceList'
import './apps/taskList'
import './apps/dscList'
import './apps/eCommerce'
import './navbar/navbarSearch'

mock.onAny().passThrough()
